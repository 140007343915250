import { useEffect, useState } from 'react';
import {
  signOut,
  updatePassword,
  updateProfile,
  deleteUser,
} from 'firebase/auth';
import {
  EVENTS,
  PLEASE_EMAIL_DEV_TEAM,
  PROFILE_PAGE_TITLES,
} from '../utils/constants';
import auth from '../firebase';
import { useAuthContext } from '../contexts/AuthContext';
import { useUserContext } from '../contexts/UserContext';
import userAPI from '../api/user/userAPI';
import ProfileUpdateTakeover from '../components/ProfileUpdateTakeover';
import ProfileButton from '../components/ProfileButton';
import ProfileConfrimTakeover from '../components/ProfileConfirmTakeover';
import apiClient from '../api';
import useIsTabletOrLarger from '../hooks/useIsTabletOrLarger';
import HamburgerButton from '../components/HamburgerButton';
import toast from 'react-hot-toast';

const ProfilePage = () => {
  const { currentUser } = useAuthContext();
  const { user, updateUser } = useUserContext();
  const isTabletOrLarger = useIsTabletOrLarger();
  const [showUsernameTakeover, setShowUsernameTakeover] = useState(false);
  const [showNativeLanguageTakeover, setShowNativeLanguageTakeover] =
    useState(false);
  const [showPasswordTakeover, setShowPasswordTakeover] = useState(false);
  const [showDeleteDataTakeover, setShowDeleteDataTakeover] = useState(false);
  const [showDeleteAccountTakeover, setShowDeleteAccountTakeover] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const USERNAME_TAKEOVER = 'username-takeover';
  const NATIVE_LANGUAGE_TAKEOVER = 'native-language-takeover';
  const PASSWORD_TAKEOVER = 'password-takeover';
  const DELETE_DATA_TAKEOVER = 'delete-data-takeover';
  const DELETE_ACCOUNT_TAKEOVER = 'delete-account-takeover';

  /**
   * Handle blur
   */
  useEffect(() => {
    if (!isTabletOrLarger) return;

    const handleBlur = (e: MouseEvent) => {
      if ((e.target as HTMLElement).id === USERNAME_TAKEOVER) {
        setShowUsernameTakeover(false);
        return;
      }

      if ((e.target as HTMLElement).id === NATIVE_LANGUAGE_TAKEOVER) {
        setShowNativeLanguageTakeover(false);
        return;
      }

      if ((e.target as HTMLElement).id === PASSWORD_TAKEOVER) {
        setShowPasswordTakeover(false);
        return;
      }

      if ((e.target as HTMLElement).id === DELETE_DATA_TAKEOVER) {
        setShowDeleteDataTakeover(false);
        return;
      }

      if ((e.target as HTMLElement).id === DELETE_ACCOUNT_TAKEOVER) {
        setShowDeleteAccountTakeover(false);
      }
    };

    window.addEventListener(EVENTS.CLICK, handleBlur);
    return () => window.removeEventListener(EVENTS.CLICK, handleBlur);
  }, [isTabletOrLarger, showUsernameTakeover]);

  const handleLogout = async () => {
    try {
      setErrorMessage('');
      await signOut(auth);

      window.location.reload();
    } catch (error) {
      setErrorMessage(
        `Error while logging out: ${(error as Error).message}. 
      ${PLEASE_EMAIL_DEV_TEAM}`,
      );
    }
  };

  const handleUserNameSubmit = async (newUserName: string) => {
    if (newUserName === user.userName) {
      toast.error('Username is the same as the current one.');
      return;
    }

    try {
      const udpatedUser = await userAPI.updateUsername(user.email, newUserName);
      updateUser(udpatedUser);

      if (!currentUser) return;

      await updateProfile(currentUser, { displayName: udpatedUser.userName });

      setShowUsernameTakeover(false);
      toast.success('Username updated');
    } catch (error) {
      setErrorMessage(`Error updating username: ${(error as Error).message}`);
    }
  };

  const handleNativeLanguageSubmit = async (newNativeLanguage: string) => {
    if (newNativeLanguage === user.nativeLanguage) {
      toast.error('Selected native language is the same as the current one.');
      return;
    }

    try {
      const udpatedUser = await userAPI.updateUser({
        userEmail: user.email,
        nativeLanguage: newNativeLanguage,
      });
      updateUser(udpatedUser);

      setShowNativeLanguageTakeover(false);
      toast.success('Native language updated');
    } catch (error) {
      setErrorMessage(
        `Error updating native language: ${(error as Error).message}`,
      );
    }
  };

  const handlePasswordSubmit = async (newPassword: string) => {
    setErrorMessage('');
    //TODO verify with current password
    if (!currentUser) return;

    try {
      await updatePassword(currentUser, newPassword);
      setShowPasswordTakeover(false);
      toast.success('Password updated');
    } catch (error) {
      setErrorMessage(`Error updating password: ${(error as Error).message}`);
    }
  };

  const handleDeleteDataConfirm = async () => {
    try {
      await userAPI.deleteUserChats(user.email);

      toast.success('Your chats have been deleted');
      setShowDeleteDataTakeover(false);
    } catch (error) {
      setErrorMessage(
        `Error deleting data: ${(error as Error).message}. 
        ${PLEASE_EMAIL_DEV_TEAM}`,
      );
    }
  };

  const handleManageSubscription = async () => {
    console.log('handleManageSubscription ', {
      customerId: user.stripeCustomerId,
    });

    console.log('handleManageSubscription user ', user);

    const { data } = await apiClient.post(
      '/api/payments/create-portal-session',
      { customerId: user.stripeCustomerId },
    );

    window.location.href = data.url;
  };

  const handleDeleteAccountConfirm = async () => {
    if (!currentUser) return;

    try {
      //TODO not working
      await deleteUser(currentUser);
    } catch (error) {
      setErrorMessage(
        `Error deleting account: ${(error as Error).message}. 
        ${PLEASE_EMAIL_DEV_TEAM}`,
      );

      return;
    }

    try {
      await userAPI.deleteUser(user.email, user.subscriptionId || '');
      toast.success('Account deleted');
      window.location.reload();
    } catch (error) {
      setErrorMessage(
        `Error deleting account: ${(error as Error).message}. 
        ${PLEASE_EMAIL_DEV_TEAM}`,
      );
    }
  };

  const getCanManageSubscription = () => {
    //todo after removing coupon codes, just conditional render based on user obj property isAllowList or whatever you go with
    if (
      user.stripeCustomerId === process.env.REACT_APP_COUPON_CODE2 ||
      user.stripeCustomerId === process.env.REACT_APP_COUPON_CODE1 ||
      user.stripeCustomerId === process.env.REACT_APP_COUPON_CODE3
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className='h-screen p-4 bg-gray-100 grid grid-rows-[auto,1fr]'>
      <div className='flex items-start -translate-x-4'>
        <HamburgerButton />
      </div>
      <div className='flex flex-col items-center gap-y-4'>
        <h1 className='font-bold text-2xl'>{user.userName}'s Profile</h1>
        <ProfileButton
          text={PROFILE_PAGE_TITLES.UPDATE_USERNAME}
          handleClick={() => setShowUsernameTakeover(true)}
        />
        <ProfileButton
          text={PROFILE_PAGE_TITLES.UPDATE_NATIVE_LANGUAGE}
          handleClick={() => setShowNativeLanguageTakeover(true)}
        />
        <ProfileButton
          text={PROFILE_PAGE_TITLES.UPDATE_PASSWORD}
          handleClick={() => setShowPasswordTakeover(true)}
        />
        <ProfileButton
          text={PROFILE_PAGE_TITLES.DELETE_YOUR_ACCOUNT}
          handleClick={() => setShowDeleteAccountTakeover(true)}
        />
        <ProfileButton
          text={PROFILE_PAGE_TITLES.DELETE_ALL_YOUR_CHATS}
          handleClick={() => setShowDeleteDataTakeover(true)}
        />
        {getCanManageSubscription() && (
          <ProfileButton
            text={PROFILE_PAGE_TITLES.MANAGE_SUBSCRIPTION}
            handleClick={handleManageSubscription}
          />
        )}
        <ProfileButton text={'Logout'} handleClick={handleLogout} />
        {showUsernameTakeover && (
          <ProfileUpdateTakeover
            title={PROFILE_PAGE_TITLES.UPDATE_USERNAME}
            id={USERNAME_TAKEOVER}
            handleCloseScreenTakeover={() => {
              setErrorMessage('');
              setShowUsernameTakeover(false);
            }}
            handleSubmit={handleUserNameSubmit}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            currentUsername={user.userName}
          />
        )}
        {showNativeLanguageTakeover && (
          <ProfileUpdateTakeover
            title={PROFILE_PAGE_TITLES.UPDATE_NATIVE_LANGUAGE}
            id={NATIVE_LANGUAGE_TAKEOVER}
            handleCloseScreenTakeover={() => {
              setErrorMessage('');
              setShowNativeLanguageTakeover(false);
            }}
            handleSubmit={handleNativeLanguageSubmit}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            currentNativeLanguage={user.nativeLanguage}
          />
        )}
        {showPasswordTakeover && (
          <ProfileUpdateTakeover
            title={PROFILE_PAGE_TITLES.UPDATE_PASSWORD}
            id={PASSWORD_TAKEOVER}
            handleCloseScreenTakeover={() => {
              setErrorMessage('');
              setShowPasswordTakeover(false);
            }}
            handleSubmit={handlePasswordSubmit}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
        )}
        {showDeleteDataTakeover && (
          <ProfileConfrimTakeover
            title={PROFILE_PAGE_TITLES.DELETE_ALL_YOUR_CHATS}
            id={DELETE_DATA_TAKEOVER}
            handleCloseScreenTakeover={() => {
              setErrorMessage('');
              setShowDeleteDataTakeover(false);
            }}
            handleConfirm={handleDeleteDataConfirm}
            errorMessage={errorMessage}
          />
        )}
        {showDeleteAccountTakeover && (
          <ProfileConfrimTakeover
            title={PROFILE_PAGE_TITLES.DELETE_YOUR_ACCOUNT}
            id={DELETE_ACCOUNT_TAKEOVER}
            handleCloseScreenTakeover={() => {
              setErrorMessage('');
              setShowDeleteAccountTakeover(false);
            }}
            handleConfirm={handleDeleteAccountConfirm}
            errorMessage={errorMessage}
          />
        )}
      </div>
    </div>
  );
};

export default ProfilePage;
