import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faComments,
  faLanguage,
  faLightbulb,
  faUsers,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import Testimonials from './Reviews';
import LogoAndTitle from '../LogoAndTitle';
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContext';
import useIsTabletOrLarger from '../../hooks/useIsTabletOrLarger';
import { PATHS, EVENTS } from '../../utils/constants';
import ContactFormTakeover from './ContactFormTakeover';
import LoginIcon from '@mui/icons-material/Login';

const CONTACT_FORM_TAKEOVER = 'contact-form-takeover';

/**
 //landing page //todos:
 -----> [ ] infiinte useffect loop in proteded routes wrapper 
- [ ] Visualization (nice picture of me using app maybe ), and/or gif, and/or link to YouTube video 
- [ ]  move stuff other than CTA further down page (at least for mobile). its bigger font so pulls eye away from CTA.
- [ ] Faqs section to address skepticism of AI language learning
- [ ] why us section (AI tutors are more affordable than human tutors)
- better logo

- make tutorial less wordy

   //TODO not working
      await deleteUser(currentUser);


- profile page with v0


- clean up prompts (live in one place other than bio)
  - will require migration, do after landing page stuff

Landing page notes:
- “We’ll never share your info with anyone” may boost conversion 
- content iwth pictures is 80% mor elikely to get read
- should pics of features be gifs? 
 */

/**
 * stripe trial //todos
 *  -In-App Notifications: Display alerts or banners within your app reminding users to add a payment method to continue service.
 * - verify ProtectedRoutesWrapper works
 * - test backend logic with customer creation (new user) and webhook stuff..may need new user db property `trialExpires` to check if trial is expiring in next 3 days
 */

const LandingPageComponent = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuthContext();
  const isTabletOrLarger = useIsTabletOrLarger();
  const [isContactFormTakeoverOpen, setIsContactFormTakeoverOpen] =
    useState(false);

  useEffect(() => {
    if (currentUser) navigate(PATHS.CHAT);
  }, [currentUser, navigate]);

  /**
   * Handle contact form takeover blur
   */
  useEffect(() => {
    if (!isTabletOrLarger) return;

    const handleBlur = (e: MouseEvent) => {
      if ((e.target as HTMLElement).id === CONTACT_FORM_TAKEOVER) {
        setIsContactFormTakeoverOpen(false);
      }
    };

    window.addEventListener(EVENTS.CLICK, handleBlur);
    return () => window.removeEventListener(EVENTS.CLICK, handleBlur);
  }, [isTabletOrLarger]);

  return (
    <div className='bg-blue-500 '>
      <LogoAndTitle />
      <button
        onClick={() => navigate(PATHS.LOGIN)}
        className='bg-white flex hover:bg-gray-200 font-semibold text-black p-1 xl:p-2 rounded-md transition duration-200 absolute top-5 right-5 shadow-lg'
      >
        <LoginIcon className='mr-1' />
        <p>Login</p>
      </button>
      <CTA />
      <Features />
      <Testimonials />
      <FolllowUpCTA />
      <Footer {...{ setIsContactFormTakeoverOpen }} />
      {isContactFormTakeoverOpen && (
        <ContactFormTakeover
          id={CONTACT_FORM_TAKEOVER}
          closeContactFormTakeover={() => setIsContactFormTakeoverOpen(false)}
        />
      )}
    </div>
  );
};

const CTA = () => {
  return (
    <section className='text-white pt-32'>
      <div className='container mx-auto px-2 text-center gap-y-3 flex flex-col items-center'>
        <h1 className='text-3xl md:text-4xl font-bold'>
          Practice languages with AI
        </h1>
        <p className='text-lg md:text-xl font-medium'>
          Improve your fluency with personalized feedback, for a fraction of the
          cost of human tutors.
        </p>
        <TryForFreeButton />
      </div>
    </section>
  );
};

const TryForFreeButton = () => {
  const navigate = useNavigate();

  return (
    <button
      type='button'
      onClick={() => navigate(PATHS.SIGNUP)}
      className='px-6 py-3 bg-white text-black shadow-lg rounded-xl hover:bg-gray-100 w-44'
    >
      <p className='font-bold'>Try for free</p>
      <p className='text-sm font-semibold'>No card required!</p>
    </button>
  );
};

const Features = () => {
  return (
    <section className='py-12'>
      <div className='container mx-auto px-4'>
        <h2 className='text-3xl md:text-3xl font-bold text-center mb-4 text-white'>
          Features
        </h2>
        <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8'>
          <FeatureCard
            icon={faComments}
            title='Chat with bilingual AI tutors via voice or text'
            description='Engage in real-time conversations with AI tutors to improve your language skills.'
          />
          <FeatureCard
            icon={faLanguage}
            title='Get translations with the click of a button'
            description='Instantly translate words and phrases to understand and communicate better.'
          />
          <FeatureCard
            icon={faLightbulb}
            title='Get suggestions on how to reply'
            description='Receive smart suggestions to improve your responses and language skills.'
          />
          <FeatureCard
            icon={faUsers}
            title='Each language has multiple tutors with distinct personalities'
            description='Learn from a variety of AI tutors, each with unique teaching styles and interests.'
          />
        </div>
      </div>
    </section>
  );
};

interface FeatureCardProps {
  icon: IconDefinition;
  title: string;
  description: string;
}

const FeatureCard = ({ icon, title, description }: FeatureCardProps) => (
  <div className='bg-white p-6 rounded-xl shadow-lg text-center'>
    <FontAwesomeIcon icon={icon} className='text-blue-600 text-3xl mb-4' />
    <h3 className='text-xl font-semibold mb-2'>{title}</h3>
    <p>{description}</p>
  </div>
);

const FolllowUpCTA = () => {
  return (
    <section className='text-white pt-16'>
      <div className='container mx-auto px-4 text-center'>
        <h2 className='text-2xl md:text-3xl font-bold mb-4'>
          Start Your Language Learning Journey Today!
        </h2>
        <TryForFreeButton />
      </div>
    </section>
  );
};

const Footer = ({
  setIsContactFormTakeoverOpen,
}: {
  setIsContactFormTakeoverOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <footer className='text-white py-8'>
      <div className='container mx-auto px-4 text-center gap-y-4 flex flex-col justify-center items-center'>
        <button
          type='button'
          onClick={() => setIsContactFormTakeoverOpen(true)}
          className='bg-white hover:bg-gray-200 font-bold text-black p-2 rounded-md transition duration-200 w-fit shadow-lg'
        >
          Contact us
        </button>
        <p>&copy; 2024 Lingua Chat Inc. All rights reserved.</p>
        <div className='flex gap-x-2 items-center'>
          <a
            href='/lingua-chat-terms-of-use.pdf'
            target='_blank'
            rel='noopener noreferrer'
          >
            Terms of Use
          </a>
          <p className='text-xl'>|</p>
          <a
            href='/lingua-chat-privacy-policy.pdf'
            target='_blank'
            rel='noopener noreferrer'
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </footer>
  );
};

export default LandingPageComponent;
