import { Navigate, Outlet } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';
import { DEV_TEAM_EMAIL, PATHS } from '../utils/constants';
import { useUserContext } from '../contexts/UserContext';
import useIsTrialExpiringInNext3Days from '../hooks/useIsTrialExpiringInNext3Days';
import toast from 'react-hot-toast';
import { useEffect } from 'react';
import apiClient from '../api';

// Convert current date to Unix timestamp (seconds since epoch)
const currentTimestamp = Math.floor(Date.now() / 1000); //todo verify UTC // todo maybe move to top of file

//todo do i need this anymore? maybe just hook in app.tsx
const ProtectedRoutesWrapper = () => {
  const { currentUser } = useAuthContext();
  const { user } = useUserContext();
  const isTrialExpiringInNext3Days = useIsTrialExpiringInNext3Days();

  console.log(
    'isTrialExpiringInNext3Days ',
    isTrialExpiringInNext3Days,
    'user?.planActive ',
    user?.planActive,
    'user?.planExpires ',
    user?.planExpires,
  );

  if (user.planExpires) {
    console.log(
      'user.planExpires > currentTimestamp ',
      user.planExpires > currentTimestamp,
    );
  }

  useEffect(() => {
    if (
      isTrialExpiringInNext3Days &&
      user?.planActive &&
      user?.planExpires &&
      user.planExpires > currentTimestamp
    ) {
      if (
        window.confirm(
          `Your trial will expire soon. You can click "Ok" to add a payment method now. Or click "Cancel" if you want to do that later via the Profile page. Need help? Email ${DEV_TEAM_EMAIL}.`,
        )
      ) {
        //todo this is copied from ProfilePage.tsx, abstract function to api/index.ts
        const handleManageSubscription = async () => {
          try {
            const { data } = await apiClient.post(
              '/api/payments/create-portal-session',
              { customerId: user.stripeCustomerId },
            );

            window.location.href = data.url;
          } catch (error) {
            console.error(
              'Error creating portal session in protectedRouteWrapper handleManageSubscription: ',
              error,
            );
            toast.error(
              `Sorry, error creating Stripe portal session. Please click "Manage Subscription" on the Profile page, or email ${DEV_TEAM_EMAIL} for assistance.`,
              {
                duration: 10000,
              },
            );
          }
        };

        handleManageSubscription();
      }
    }
  }, [
    isTrialExpiringInNext3Days,
    user?.planActive,
    user.planExpires,
    user.stripeCustomerId,
  ]); //todo maybe empty dep arr?

  if (!currentUser) {
    return <Navigate to={PATHS.LOGIN} replace />; //todo test if this works!
  } else if (
    user.planActive === false ||
    (user.planExpires && user.planExpires > currentTimestamp)
  ) {
    console.log('user.planActive === false', user.planActive === false);

    if (user.planExpires && user.planExpires > currentTimestamp) {
      console.log(
        'user.planExpires > currentTimestamp ',
        user.planExpires > currentTimestamp,
      );
    }

    return <Navigate to={PATHS.SUBSCRIPTION} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoutesWrapper;
