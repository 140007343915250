import classNames from 'classnames';
import { useState } from 'react';

interface ReviewProps {
  image: string;
  name: string;
  location: string;
  review: string;
}

const Review = ({ image, name, location, review }: ReviewProps) => {
  const [isImageLoading, setIsImageLoading] = useState(true);

  return (
    <div className='bg-white text-black rounded-xl p-4 flex flex-col max-w-xs justify-center shadow-lg'>
      <h1 className='mb-2'>{review}</h1>
      <div className='flex items-center'>
        <img
          src={image}
          alt=''
          onLoad={() => setIsImageLoading(false)}
          className={classNames(
            'rounded-full overflow-hidden object-cover mr-2 w-12 h-12',
            isImageLoading && 'animate-pulse bg-gray-300',
          )}
        />
        <div>
          <p className='font-bold'>{name}</p>
          <p className='font-semibold'>{location}</p>
        </div>
      </div>
    </div>
  );
};

export default Review;
