import { FormEvent, useState } from 'react';
import contactAPI from '../../api/contactAPI';
import Takeover from '../Takeover';
import toast from 'react-hot-toast';

interface ContactFormTakeoverProps {
  id: string;
  closeContactFormTakeover: () => void;
}

const ContactFormTakeover = ({
  id,
  closeContactFormTakeover,
}: ContactFormTakeoverProps) => {
  const [messageValue, setMessageValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [nameValue, setNameValue] = useState('');

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await contactAPI.postContactFormContent({
      email: emailValue,
      message: messageValue,
      name: nameValue,
    });

    toast.success("We'll get back to you soon!");
    closeContactFormTakeover();
  };

  return (
    <Takeover
      handleCloseScreenTakeover={closeContactFormTakeover}
      title={'Contact us'}
      id={id}
    >
      <form onSubmit={handleSubmit} className='flex flex-col gap-y-4'>
        <input
          value={emailValue}
          onChange={e => {
            setEmailValue(e.target.value);
          }}
          placeholder='Email'
          className='w-full p-2 border rounded-lg text-black'
          required
          type='email'
        />
        <input
          value={nameValue}
          onChange={e => {
            setNameValue(e.target.value);
          }}
          placeholder='Name (optional)'
          className='w-full p-2 border rounded-lg text-black'
        />
        <textarea
          value={messageValue}
          onChange={e => setMessageValue(e.target.value)}
          className='p-2 border-2 rounded-lg h-60 text-black'
          placeholder='Message'
          required
        />
        <button
          type='submit'
          className='bg-blue-500 text-white rounded-lg shadow-lg p-4 font-bold hover:bg-blue-600'
        >
          Send
        </button>
      </form>
    </Takeover>
  );
};

export default ContactFormTakeover;
