import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SubscribeButton from '../components/SubscribeButton';
import { useUserContext } from '../contexts/UserContext';
import { PATHS } from '../utils/constants';

const SubscriptionPage = () => {
  const { user } = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    console.log('subscription page user.planActive: ', user.planActive);

    if (user.planActive) {
      // navigate(PATHS.CHAT);
    }
  }, [navigate, user]);

  return (
    <div className='bg-blue-500 text-white h-screen flex flex-col gap-y-4 items-center justify-center p-12'>
      <div className='font-extrabold text-xl text-center'>
        <p>Your free trial has expired.</p>
        <p>Please subscribe to continue using Lingua Chat.</p>
      </div>
      <SubscribeButton />
    </div>
  );
};

export default SubscriptionPage;
