import classNames from 'classnames';
import Review from './Review';

const Reviews = () => {
  const reviews = [
    {
      review:
        '"Your app is the best way to improve my English I have seen so far. I love it!"',
      image: '/testimonialImages/alejandro.webp',
      name: 'Alejandro Re',
      location: 'Argentina',
    },
    {
      review:
        '“Game changing app, it’s like having my own private tutor!! The AI teacher gets to know my teaching preferences and can hold a conversation based on my personal interests!“',
      image: '/testimonialImages/valentina.webp',
      name: 'Valentina Karpova',
      location: 'USA',
    },
    {
      review:
        '"Forget about “¿Donde está lá biblioteca?”. Learn about what matters to you. Don’t like today’s lesson? Change it."',
      image: '/testimonialImages/henrique.webp',
      name: 'Henrique Casagrande',
      location: 'USA',
    },
    {
      review:
        '"Great app! Super helpful with conversation and vocabulary. Definitely helped get past a plateau."',
      image: '/testimonialImages/stephen.webp',
      name: 'Stephen Cheney',
      location: 'USA',
    },
    {
      review:
        '"This app is great!!! I’m learning much faster than with typical language apps. The characters are engaging and the interface offers so many options."',
      image: '/testimonialImages/gary.webp',
      name: 'Gary Adams',
      location: 'USA',
    },
  ];

  return (
    <div className='flex flex-col items-center mb-8 ontainer mx-auto px-4'>
      <h2 className='font-bold text-3xl mb-4 text-white'>Reviews</h2>
      <div
        className={classNames(
          // 'w-full mb-4 flex gap-5 justify-center',
          'grid grid-cols-1 md:grid-cols-2 xl:grid-cols-5  gap-8',
          // !isTabletOrLarger && 'flex-col items-center',
        )}
      >
        {reviews.map(review => (
          <Review key={review.name} {...review} />
        ))}
      </div>
    </div>
  );
};

export default Reviews;
